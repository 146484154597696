import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { StaticImage } from "gatsby-plugin-image"
import Fade from "react-reveal/Fade";

const IndustryList = () => {
  const { t } = useTranslation()

  return (
    <div className="overflow-x-scroll no-scrollbar">
      <div className="w-[300vw] md:w-auto md:mx-auto md:px-10 md:max-w-7xl">
        <div className="flex justify-between">
          <Fade big duration={1000}>
            <div className="ml-5">
              <StaticImage
                src="../images/food-icon.png"
                formats={["auto", "webp", "avif"]}
                alt={t("foodCaption")}
              />
              <p className="font-light text-lg text-white text-center w-[11.25rem]">
                {t("foodCaption")}
              </p>
            </div>
          </Fade>
          <Fade big duration={1000} delay={100}>
            <div>
              <StaticImage
                src="../images/glass-icon.png"
                formats={["auto", "webp", "avif"]}
                alt={t("glassCaption")}
              />
              <p className="font-light text-lg text-white text-center w-[11.25rem]">
                {t("glassCaption")}
              </p>
            </div>
          </Fade>
          <Fade big duration={1000} delay={200}>
            <div>
              <StaticImage
                src="../images/construction-icon.png"
                formats={["auto", "webp", "avif"]}
                alt={t("constructionCaption")}
              />
              <p className="font-light text-lg text-white text-center w-[11.25rem]">
                {t("constructionCaption")}
              </p>
            </div>
          </Fade>
          <Fade big duration={1000} delay={300}>
            <div>
              <StaticImage
                src="../images/leather-icon.png"
                formats={["auto", "webp", "avif"]}
                alt={t("leatherCaption")}
              />
              <p className="font-light text-lg text-white text-center w-[11.25rem]">
                {t("leatherCaption")}
              </p>
            </div>
          </Fade>
          <Fade big duration={1000} delay={400}>
            <div>
              <StaticImage
                src="../images/packaging-icon.png"
                formats={["auto", "webp", "avif"]}
                alt={t("packagingCaption")}
              />
              <p className="font-light text-lg text-white text-center w-[11.25rem]">
                {t("packagingCaption")}
              </p>
            </div>
          </Fade>
          <Fade big duration={1000} delay={500}>
            <div>
              <StaticImage
                src="../images/ceramics-icon.png"
                formats={["auto", "webp", "avif"]}
                alt={t("ceramicsCaption")}
              />
              <p className="font-light text-lg text-white text-center w-[11.25rem]">
                {t("ceramicsCaption")}
              </p>
            </div>
          </Fade>
        </div>
        <div className="flex justify-between">
          <Fade big duration={1000} delay={600}>
            <div className="ml-5">
              <StaticImage
                src="../images/textile-icon.png"
                formats={["auto", "webp", "avif"]}
                alt={t("textileCaption")}
              />
              <p className="font-light text-lg text-white text-center w-[11.25rem]">
                {t("textileCaption")}
              </p>
            </div>
          </Fade>
          <Fade big duration={1000} delay={700}>
            <div>
              <StaticImage
                src="../images/mines-icon.png"
                formats={["auto", "webp", "avif"]}
                alt={t("minesCaption")}
              />
              <p className="font-light text-lg text-white text-center w-[11.25rem]">
                {t("minesCaption")}
              </p>
            </div>
          </Fade>
          <Fade big duration={1000} delay={800}>
            <div>
              <StaticImage
                src="../images/wood-icon.png"
                formats={["auto", "webp", "avif"]}
                alt={t("woodCaption")}
              />
              <p className="font-light text-lg text-white text-center w-[11.25rem]">
                {t("woodCaption")}
              </p>
            </div>
          </Fade>
          <Fade big duration={1000} delay={900}>
            <div>
              <StaticImage
                src="../images/jewelry-icon.png"
                formats={["auto", "webp", "avif"]}
                alt={t("jewelryCaption")}
              />
              <p className="font-light text-lg text-white text-center w-[11.25rem]">
                {t("jewelryCaption")}
              </p>
            </div>
          </Fade>
          <Fade big duration={1000} delay={1000}>
            <div>
              <StaticImage
                src="../images/automative-icon.png"
                formats={["auto", "webp", "avif"]}
                alt={t("automativeCaption")}
              />
              <p className="font-light text-lg text-white text-center w-[11.25rem]">
                {t("automativeCaption")}
              </p>
            </div>
          </Fade>
          <Fade big duration={1000} delay={1100}>
            <div>
              <StaticImage
                src="../images/fiber-icon.png"
                formats={["auto", "webp", "avif"]}
                alt={t("fiberCaption")}
              />
              <p className="font-light text-lg text-white text-center w-[11.25rem]">
                {t("fiberCaption")}
              </p>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  )
}

export default IndustryList

import React, { useRef, useState } from "react"
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from "gatsby-plugin-image"
import Slide from "react-reveal/Slide";

const Hero = () => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-center align-center flex-col">
      <Slide bottom duration={1000}>
        <h1 className="lg:max-w-6xl text-white leading-none md:leading-normal lg:leading-none text-center block font-bold p-10 py-20 text-5xl md:p-16 md:text-7xl lg:text-9xl whitespace-pre-line">
          {t("heroTitle")}
        </h1>
      </Slide>

      <Slide bottom duration={1000}>
        <p className="max-w-sm text-white text-center w-8/12 lg:w-6/12 md:pt-8 py-16 md:py-32 block font-thin lg:text-lg">
          {t("heroDescription")}
        </p>
      </Slide>
      <div className="absolute z-10 bottom-24 right-16 hidden lg:block">
        <Link to="#welcome">
          <StaticImage
            src="../images/scroll-down.png"
            width={30}
            alt="scroll-down"
          />
        </Link>
      </div>
    </div>

  )
}

export default Hero

import * as React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";
import Seo from "../components/seo"
import Footer from "../components/footer"
import Hero from "../components/hero"
import WelcomeSection from "../components/WelcomeSection"
import ProductList from "../components/products/ProductList"
import Ticker from "../components/Ticker"
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import Header from "../components/header.js"
import IndustryList from "../components/industryList"

const IndexPage = ({ data, location }) => {
  const { t, i18n } = useTranslation()
  const categories = data.allStrapiCategory.nodes

  const brand = (i18n.language === "tr" ? data.site?.siteMetadata?.titleTr : data.site?.siteMetadata?.titleEn) || `tbant`

  const schema = {
    "@context": "https://schema.org/",
    "@type": "Organization",
    "name": brand,
    "url": data.site.siteMetadata.siteUrl + location.pathname,
    "logo": "https://tbantsan.com/static/4681537f4a6b060de47d5cafd10d982b/b9a68/logo-dark.png",
    "image": "https://tbantsan.com/static/4681537f4a6b060de47d5cafd10d982b/b9a68/logo-dark.png",
    "email": "iletisim@tbantsan.com",
    "description": t("welcomeDescription"),
    "address": "Tuzla Deri OSB Vakum Cad. No:13/A\nÇ3-3 34957 Tuzla / İstanbul",
    "telephone": "+90 216 394 83 70",
    "sameAs": "https://www.linkedin.com/company/tbantsan/",
    "hasOfferCatalog": ""
  }

  return (
    <>
      <Seo description={t("metaHomeDescription")} keywords={t("metaHomeKeywords")} schema={schema} />
      <div className="bg-hero bg-cover bg-center bg-no-repeat">
        <Header siteTitle={t("welcomeHome")} />
        <Hero />
      </div>

      <div id="welcome" className="w-full text-center bg-reallyDarkBlue">
        <WelcomeSection />
      </div>

      <div className="bg-reallyDarkBlue">
        <div className="px-6 md:px-10 pt-32 pb-16 md:py-20 text-white max-w-7xl mx-auto">
          <Fade left duration={1000}>
            <h2 className="w-full pb-20 text-3xl lg:text-6xl font-bold text-white">
              {t("ourProducts")}
            </h2>
          </Fade>
          <div className="flex flex-wrap -mx-2 md:-mx-5">
            <ProductList categories={categories} />
          </div>
        </div>
      </div>

      <div className="bg-reallyDarkBlue">
        <div className="px-6 md:px-10 py-16 md:py-20 text-white flex flex-wrap max-w-7xl mx-auto">
          <div className="w-full md:flex justify-between">
            <Fade left duration={1000}>
              <p className="max-w-lg md:w-3/5 py-8 text-4xl md:text-3xl lg:text-5xl font-bold leading-tight">
                {t("accessoriesTitle1")}{" "}
                <span className="text-blue">{t("accessoriesTitle2")}</span>{" "}
                {t("accessoriesTitle3")}
              </p>
            </Fade>
            <div className="max-w-lg md:w-2/5 text-xs font-thin py-8">
              <Fade right duration={1000}>
                <p className="text-lg pb-8">
                  {t("accessoriesHomeDescription")}
                </p>
              </Fade>
              <Fade right duration={1000}>
                <Link to="/accessories" className="w-max block p-2 text-sm transition text-white bg-purple border border-transparent hover:border-white hover:bg-darkBlue">
                  {t("accessoriesCTA")}
                </Link>
              </Fade>
            </div>
          </div>
          <div className="py-16 lg:py-32">
            <div className="overflow-hidden">
              <StaticImage
                src="../images/accessories.png"
                formats={["auto", "webp", "avif"]}
                alt="accesories"
                className="transition hover:scale-110"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-our-customers h-auto w-full bg-cover bg-no-repeat py-20">
        <div className="">
          <Slide left duration={1000}>
            <h2 className="px-6 md:px-10 max-w-7xl mx-auto text-3xl lg:text-5xl font-bold text-white">
              {t("ourIndustriesTitle")}
            </h2>
          </Slide>
          <div className="py-16 pb-20">
            <IndustryList />
          </div>
          <div className="w-full md:flex justify-between px-6 md:px-10 max-w-7xl mx-auto">
            <Slide left duration={1000}>
              <p className="max-w-lg text-lg text-white">
                {t("ourIndustriesDescription")}
              </p>
            </Slide>
            <Slide right duration={1000}>
              <Link to="/contact" className="w-max my-6 md:my-auto ml-auto block p-2 text-sm transition text-white bg-purple border border-transparent hover:border-white hover:bg-darkBlue">
                {t("ourIndustriesCTA")}
              </Link>
            </Slide>
          </div>
        </div>
      </div>

      {/* <div className="bg-reallyDarkBlue">
        <div className="px-5 py-16 md:p-20 text-white max-w-7xl mx-auto">
          <div className="w-full md:flex justify-between px-6">
            <p className="max-w-lg text-lg pb-8 md:pb-0 md:pr-8">
              We work with companies from various industries and various
              countries. Contact us for our products!
            </p>
            <Link className="w-max my-auto ml-auto block p-2  text-sm cursor-pointer text-white bg-purple">
              CONTACT
            </Link>
          </div>
        </div>
      </div> */}

      <div className="bg-reallyDarkBlue">
        <div className="px-6 md:px-10 py-16 md:py-20 text-white flex flex-wrap max-w-7xl mx-auto">
          <Slide left duration={1000}>
            <h2 className="w-full pb-12 text-3xl lg:text-6xl font-bold text-white">
              {t("ourProductionTitle")}
            </h2>
          </Slide>

          <div className="w-full md:flex justify-between">
            <Slide left duration={1000}>
              <p className="text-xl text-blue py-6 md:py-0 font-bold">{t("ourProductionSubTitle")}</p>
            </Slide>
            <Slide right duration={1000}>
              <p className="text-lg max-w-lg lg:max-w-3xl font-light">
                {t("ourProductionDescription")}
              </p>
            </Slide>
          </div>

          <div className="w-full md:w-4/5 block md:flex md:flex-wrap mx-auto py-32">
            <Slide bottom duration={1000}>
              <div className="w-full flex md:w-1/2 py-6">
                <svg width="32" height="32" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.95498 9.44414L8.44427 10.9886L12.1675 6.35525" stroke="#3262AA" strokeWidth="0.579167" strokeLinecap="round" />
                  <circle cx="9.27246" cy="9.25635" r="8.39792" transform="rotate(-180 9.27246 9.25635)" stroke="white" strokeWidth="0.579167" />
                </svg>
                <div className="w-4/5 mx-5">
                  <p className="text-2xl font-bold">{t("ourProduction1Title")}</p>
                  <p className="text-lg py-6 font-light">
                    {t("ourProduction1Description")}
                  </p>
                </div>
              </div>
            </Slide>
            <Slide bottom duration={1000}>
              <div className="w-full flex md:w-1/2 py-6">
                <svg width="32" height="32" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.95498 9.44414L8.44427 10.9886L12.1675 6.35525" stroke="#3262AA" strokeWidth="0.579167" strokeLinecap="round" />
                  <circle cx="9.27246" cy="9.25635" r="8.39792" transform="rotate(-180 9.27246 9.25635)" stroke="white" strokeWidth="0.579167" />
                </svg>
                <div className="w-4/5 mx-5">
                  <p className="text-2xl font-bold">{t("ourProduction2Title")}</p>
                  <p className="text-lg py-6 font-light">
                    {t("ourProduction2Description")}
                  </p>
                </div>
              </div>
            </Slide>
            <Slide bottom duration={1000}>
              <div className="w-full flex md:w-1/2 py-6">
                <svg width="32" height="32" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.95498 9.44414L8.44427 10.9886L12.1675 6.35525" stroke="#3262AA" strokeWidth="0.579167" strokeLinecap="round" />
                  <circle cx="9.27246" cy="9.25635" r="8.39792" transform="rotate(-180 9.27246 9.25635)" stroke="white" strokeWidth="0.579167" />
                </svg>
                <div className="w-4/5 mx-5">
                  <p className="text-2xl font-bold">{t("ourProduction3Title")}</p>
                  <p className="text-lg py-6 font-light">
                    {t("ourProduction3Description")}
                  </p>
                </div>
              </div>
            </Slide>
            <Slide bottom duration={1000}>
              <div className="w-full flex md:w-1/2 py-6">
                <svg width="32" height="32" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.95498 9.44414L8.44427 10.9886L12.1675 6.35525" stroke="#3262AA" strokeWidth="0.579167" strokeLinecap="round" />
                  <circle cx="9.27246" cy="9.25635" r="8.39792" transform="rotate(-180 9.27246 9.25635)" stroke="white" strokeWidth="0.579167" />
                </svg>
                <div className="w-4/5 mx-5">
                  <p className="text-2xl font-bold">{t("ourProduction4Title")}</p>
                  <p className="text-lg py-6 font-light">
                    {t("ourProduction4Description")}
                  </p>
                </div>
              </div>
            </Slide>
          </div>
        </div>
      </div>

      <Ticker />

      <Footer />
    </>
  )
}
export default IndexPage

export const query = graphql`
  query ($language: String!) {
    allStrapiCategory(filter: {locale: {eq: $language}}) {
      nodes {
        id
        slug
        title
        locale
        featuredImg {
          alternativeText
          localFile {
            url
            childImageSharp {
              gatsbyImageData(aspectRatio: 1.3)
            }
          }
        }
      }
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        titleTr
        titleEn
        siteUrl
      }
    }
  }
`

import React from "react"
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import Slide from "react-reveal/Slide";

const WelcomeSection = () => {
  const { t } = useTranslation();
  return (
    <div className="py-16 md:py-32">
      <Slide bottom duration={1000}>
        <p className="text-lg pb-8 text-blue">{t("welcomeHome")}</p>
      </Slide>
      <Slide bottom>
        <p className="w-3/4 px-2 md:w-1/2 lg:w-2/3 py-6 text-4xl lg:text-6xl leading-none mx-auto text-white font-bold">
          {t("welcomeTitle")}
        </p>
      </Slide>
      <Slide bottom duration={1000}>
        <p className="w-2/3 px-2 md:w-2/5 py-6 mx-auto text-base lg:text-lg font-thin text-white">
          {t("welcomeDescription")}
        </p>
      </Slide>
      <Slide bottom duration={1000}>
        <Link to="/about" className="w-max inline-block p-2 mx-auto text-sm transition text-white bg-purple border border-transparent hover:border-white hover:bg-darkBlue">
          {t("welcomeButton")}
        </Link>
      </Slide>
    </div>
  )
}

export default WelcomeSection
